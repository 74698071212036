import { Col, Row, Card, Button } from 'reactstrap';
import React from "react";
import imgT1 from '../../assets/images/01-thumbnail.jpg'
import imgT2 from '../../assets/images/aviation.jpg'
import imgT3 from '../../assets/images/office.jpg'
import imgT4 from '../../assets/images/04-thumbnail.jpg'
import imgT5 from '../../assets/images/cards1.jpg'
import imgT6 from '../../assets/images/oil-rig.jpg'
import '../../assets/css/Main.css'
import { Link } from "react-router-dom";

export default function Projects(props) {

    const projectsItems = [
        {
            id: 1,
            img: imgT2,
            altText: "project 2",
            heading: "Aviation Training",
            subHeading: "Software Modernisation",
            subHeading2: "React",
            subHeading3: "ASP.NET Core",
            subHeading4: "MS SQL",

        },
        {
            id: 2,
            img: imgT1,
            altText: "project 3",
            heading: "Aviation Documentation",
            subHeading: "Business Application",
            subHeading2: "React",
            subHeading3: "ASP.NET Core",
            subHeading4: "MS SQL",

        },
        {
            id: 3,
            img: imgT3,
            altText: "project 1",
            heading: "Project Management",
            subHeading: "Business Application",
            subHeading2: "ASP.NET",
            subHeading3: "MS SQL",

        },
        {
            id: 4,
            img: imgT5,
            altText: "project 2",
            heading: "Card Management",
            subHeading: "Business Application",
            subHeading2: "React",
            subHeading3: "ASP.NET Core",
            subHeading4: "MS SQL",

        },
        {
            id: 5,
            img: imgT6,
            altText: "project 3",
            heading: "Lease Validation Tool",
            subHeading: "Business Application",
            subHeading2: "VBA",
            subHeading3: "MS SQL",
            subHeading4: "Integration",

        },
        {
            id: 6,
            img: imgT4,
            altText: "project 1",
            heading: "IFRS16 Reporting",
            subHeading: "Business Application",
            subHeading2: "VBA",
            subHeading3: "MS SQL",
            subHeading4: "SSIS",

        },
    ]



    return (
        <>
            <Row>
                {projectsItems.map((item, index) => (
                    <Col key={index} xl="4" lg="6" xs="12" className="">
                        <div className={`div-container ${index < 4 ? "visible" : ""}`}>
                            <Card className="mb-4 mt-1 ms-2 me-2" style={props.style}>
                                <div className="">
                                    <img className="img-fluid" src={item.img} alt={item.altText} />
                                </div>
                                <div className="portfolio-caption">
                                    <div className="portfolio-caption-heading">{item.heading}</div>
                                    <div className="portfolio-caption-subheading">{item.subHeading}</div>
                                    <div className="portfolio-caption-subheading2 pt-2">
                                        <span style={{ padding: "8px" }}>{item.subHeading2}</span>|
                                        <span style={{ padding: "8px" }}>{item.subHeading3}</span>
                                        {item.subHeading4 &&
                                            <>
                                                <span>|</span>
                                                <span style={{ padding: "8px" }}>{item.subHeading4}</span>
                                            </>
                                        }
                                    </div>
                                </div>
                                <Row>
                                    <Col xs="12" className="mb-2 text-center">
                                        <Link to={`/projects/${item.id}`} style={{ textDecoration: "none" }}>
                                            <Button className="main-button btn-orange ms-2" size="md" >Learn more</Button>
                                        </Link>
                                    </Col>
                                </Row>
                            </Card>
                        </div>
                    </Col>
                ))}
            </Row>
        </>
    );
}