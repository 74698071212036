import axios from "axios";
import { graphConfig, graphLoginRequest, loginRequest, msalConfig } from "../authconfig.js";
import { PublicClientApplication } from '@azure/msal-browser';

const axiosinstance = axios.create({
    baseURL: process.env.REACT_APP_BASE_API_URL,
});

const msalInstance = new PublicClientApplication(msalConfig);

const tokenRefresh = (async (request) => {
    const account = msalInstance.getAllAccounts()[0];

    let res = await msalInstance.acquireTokenSilent({
        ...request,
        account: account
    }).then((response) => {
        return response
    }).catch((e) => {
        console.log("Error acquiring token")
    }
    );
    return res
})

const base = {

    getAll: async (url) => {
        let res = tokenRefresh(loginRequest).then(async (tokenRes) => {
            const response = await axiosinstance.get(url,
                { withCredentials: false, headers: { Authorization: `Bearer ` + tokenRes.accessToken } });
            return response.data;
        })
        return res
    },

    get: async (url, id) => {
        let res = tokenRefresh(loginRequest).then(async (tokenRes) => {
            const response = await axiosinstance.get(url + '/' + id,
                { withCredentials: false, headers: { Authorization: `Bearer ` + tokenRes.accessToken } });
            return response.data;
        })
        return res
    },

    getPost: async (url, data) => {
        let res = tokenRefresh(loginRequest).then(async (tokenRes) => {
            const response = await axiosinstance.post(url, data,
                { withCredentials: false, headers: { Authorization: `Bearer ` + tokenRes.accessToken } });
            return response.data;
        })
        return res
    },

    getPhoto: async (url) => {
        let res = tokenRefresh(graphLoginRequest).then(async (tokenRes) => {
            const response = await axiosinstance.get(graphConfig.graphPhotoEndpoint,
                { withCredentials: false, responseType: "arraybuffer", headers: { Authorization: `Bearer ` + tokenRes.accessToken } });
            return response;
        })
        return res
    },

    qry: async (url, id) => {
        let res = tokenRefresh(loginRequest).then(async (tokenRes) => {
            const response = await axiosinstance.get(url + '/' + id,
                { withCredentials: false, headers: { Authorization: `Bearer ` + tokenRes.accessToken } });
            return response.data;
        })
        return res
    },

    add: async (url, data) => {
        let res = tokenRefresh(loginRequest).then(async (tokenRes) => {
            const response = await axiosinstance.post(url, data,
                { withCredentials: false, headers: { Authorization: `Bearer ` + tokenRes.accessToken } });
            return response;
        })
        return res
    },

    update: async (url, data) => {
        let res = tokenRefresh(loginRequest).then(async (tokenRes) => {
            const response = await axiosinstance.put(url, data,
                { withCredentials: false, headers: { Authorization: `Bearer ` + tokenRes.accessToken } });
            return response;
        })
        return res
    },

    delete: async (url, id) => {
        let res = tokenRefresh(loginRequest).then(async (tokenRes) => {
            const response = await axiosinstance.put(url + '/' + id, null,
                { withCredentials: false, headers: { Authorization: `Bearer ` + tokenRes.accessToken } });
            return response.status;
        })
        return res
    }
}

export default base;