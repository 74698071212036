import React from "react";
import { Row, Col, Button } from 'reactstrap';
import ServicesData from './servicesData.js'
import { Link } from "react-router-dom";

export default function Services() {


    return (
        <div >
            <Row className="text-center pb-4  ">
                {ServicesData.map((item) => (
                    <Col key={item.id} md="6" lg="4" xs="12" className="mb-3 pt-3">
                        <figure>
                            {item.icon}
                            <figcaption>
                                <h2 className="text-center my-3 head-text">{item.heading}</h2>
                                <p className="sub-text" style={{ minHeight: "85px", textAlign: "left" }}>{item.description}</p>
                            </figcaption>
                        </figure>

                        <Row>
                            <Col xs="12" className="mb-2 text-center">
                                <Link to={`/services/${item.id}`} style={{ textDecoration: "none" }}>

                                    <Button className="main-button btn-orange ms-2" size="md" >Learn more</Button>
                                </Link>

                            </Col>
                        </Row>

                    </Col>
                ))}
            </Row>
        </div>
    );
}
