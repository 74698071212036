import axios from 'axios';


var axiosinstance = axios.create({
    baseURL: process.env.REACT_APP_BASE_API_URL,
});


axiosinstance.interceptors.response.use(
    function (successRes) {
        return successRes;
    },
    function (error) {
        //console.log("this is the respone from the APi >>>>>>>>>>>", error);
        if (error.toString().includes("Network Error")) {
            //console.log("this is the network error handled from the axios instance");
            return "";
        }
        else if (error.response.status === 401) {
            return Promise.reject("unauthourized");

        }
        return Promise.reject(error);
    }
);

export default axiosinstance;