import React, { useEffect } from 'react'
import '../../assets/css/Main.css'
import Error404 from '../../assets/images/error404.svg'

const Error404Component = () => {

    useEffect(() => {
        document.title = '404 - Page Not Found';
        const metaRobots = document.createElement('meta');
        metaRobots.setAttribute('name', 'robots');
        metaRobots.content = 'noindex';
        document.head.appendChild(metaRobots);

        return () => {
            document.head.removeChild(metaRobots);
        };
    }, []);

    return (
        <div className="">
            <div className="bg-teal p-5 mb-5">
                <h1>404 Error</h1>
                <p className="main-subheading">Oops! The page you are looking for was not found.</p>
            </div>
            <div className="container-large pt-4 pb-5">
                <div className="text-center pt-3 pb-3">
                    <img className="img404" src={Error404} alt="404 error" style={{ height: "400px" }} />
                </div>

            </div>
        </div >
    )
}

export default Error404Component