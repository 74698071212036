import React, { useEffect } from 'react'
import '../../../assets/css/Main.css'
import '../../../assets/css/custom.css'
import ProcessItems from './processItems.js'
// import { Button } from 'reactstrap'
// import { Link } from 'react-router-dom'
import TextOnImage from '../textOnImage.js'
import image from '../../../assets/images/image12.jpg'



const Process = () => {

    useEffect(() => {
        document.title = 'Process | Creating innovative IT solutions | Mobyte';

        // Create meta description
        const metaDescription = document.createElement('meta');
        metaDescription.setAttribute('name', 'description');
        metaDescription.content = 'Experience our meticulous software development process, guiding ideas to successful projects, from inception to deployment';

        // Create meta keywords
        const metaKeywords = document.createElement('meta');
        metaKeywords.setAttribute('name', 'keywords');
        metaKeywords.content = 'Ideation, requirements gathering, project planning, solution design, development, testing, deployment, support, maintenance, project management, software development process, project lifecycle';


        const metaRobots = document.createElement('meta');
        metaRobots.setAttribute('name', 'robots');
        metaRobots.content = 'index';
        document.head.appendChild(metaRobots);

        // Append both meta elements to the head
        document.head.appendChild(metaDescription);
        document.head.appendChild(metaKeywords);
        document.head.appendChild(metaKeywords);

        return () => {
            // Clean up added meta tags when component unmounts
            document.head.removeChild(metaDescription);
            document.head.removeChild(metaKeywords);
            document.head.removeChild(metaRobots);

        };
    }, []);
    const subheading = (
        // <span>
        //     Delivering&nbsp;<span className="header-brush">innovative IT solutions</span>&nbsp;using our proven 8-step process
        // </span>
        <span className="font-white">Our process</span>
    )

    return (
        <div className="">
            {/* <div className="bg-teal p-5 mb-5">
                <h1>Process</h1>
                <p className="main-subheading">We have over 35 years experience in software development and business support. We offer a wide range of services that utilise the latest technologies.</p>
            </div> */}
            {/* <div className="bg-teal ps-5 pe-5 pt-3 pb-3 mb-5">
                <div className="main-heading">Process</div>
                <p className="main-subheading">Over the past 35 years we have fined tuned our development process to ensure we offer the best service and a solution that meets our customers needs.</p>
            </div> */}

            <TextOnImage imageUrl={image} heading={"Our Process"} subheading={subheading} fromHome={false} altText={"Team working around computer"} />


            <ProcessItems />
            {/* <div className="container-large">
                <div className="">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-6 mb-5">
                                <div className="ps-5 pe-5 pb-5">
                                    <h2 className="mb-3">Stage 1: Ideation</h2>
                                    <p className="lead opacity-60 mb-0">The first stage involves brainstorming and generating ideas for potential projects. This could come from internal stakeholders or external customers.</p></div>

                                <div className="position-relative overlay overlay-solid" style={{ minHeight: "200px" }}>
                                    <img src="https://picsum.photos/seed/hjyunm/630/670" className="img-fluid" alt="" />
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="position-relative text-white overlay overlay-solid" style={{ minHeight: "200px" }}>
                                    <img src="https://picsum.photos/seed/trfvbg/630/670" className="img-fluid" alt="" />
                                    <div className="absolute-fill d-flex flex-column align-items-end z-index-2">
                                        <div className="w-100 mt-auto p-7"><h3 className="fw-normal lh-1-37 mb-4"></h3>
                                            <p className="lead opacity-100 mb-0"></p>
                                        </div>
                                    </div></div><div className="ps-8 pe-6 py-7 py-md-10">
                                    <h2 className="lh-1-37 mb-5">Stage 2: Requirements</h2>
                                    <p className="lead opacity-60 lh-1-75 mb-6">We work with stakeholders to gather requirements for the project. This includes defining the scope, objectives, and constraints for the project.</p>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-6 mb-5">
                                <div className="ps-6 pe-8 pt-md-8 pb-7 pb-md-10">
                                    <h2 className="lh-1-37 mb-5">Stage 3: Planning</h2>
                                    <p className="lead opacity-60 lh-1-75 mb-0">We create a detailed plan for the project, including a timeline, budget, resources required, and the process for executing the project.</p></div>
                                <div className="position-relative text-white overlay overlay-solid" style={{ minHeight: "200px" }}>
                                    <img src="https://picsum.photos/seed/hjyunm/630/670" className="img-fluid" alt="" />
                                    <div className="absolute-fill d-flex flex-column align-items-end z-index-2">
                                        <div className="w-100 mt-auto p-7">
                                            <h3 className="fw-normal lh-1-37 mb-4"></h3>
                                            <p className="lead opacity-100 mb-0"></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="position-relative text-white overlay overlay-solid" style={{ minHeight: "200px" }}>
                                    <img src="https://picsum.photos/seed/trfvbg/630/670" className="img-fluid" alt="" />
                                    <div className="absolute-fill d-flex flex-column align-items-end z-index-2">
                                        <div className="w-100 mt-auto p-7"><h3 className="fw-normal lh-1-37 mb-4"></h3>
                                            <p className="lead opacity-100 mb-0"></p>
                                        </div>
                                    </div></div><div className="ps-8 pe-6 py-7 py-md-10">
                                    <h2 className="lh-1-37 mb-5">Stage 4: Design</h2>
                                    <p className="lead opacity-60 lh-1-75 mb-6">We create the design for the solution, including both the technical design and the user interface design.</p>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-6 mb-5">
                                <div className="ps-6 pe-8 pt-md-8 pb-7 pb-md-10">
                                    <h2 className="lh-1-37 mb-5">Stage 5: Development</h2>
                                    <p className="lead opacity-60 lh-1-75 mb-0">We start developing the solution, including writing code, building the infrastructure, and testing the solution.</p></div>
                                <div className="position-relative text-white overlay overlay-solid" style={{ minHeight: "200px" }}>
                                    <img src="https://picsum.photos/seed/hjyunm/630/670" className="img-fluid" alt="" />
                                    <div className="absolute-fill d-flex flex-column align-items-end z-index-2">
                                        <div className="w-100 mt-auto p-7">
                                            <h3 className="fw-normal lh-1-37 mb-4"></h3>
                                            <p className="lead opacity-100 mb-0"></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="position-relative text-white overlay overlay-solid" style={{ minHeight: "200px" }}>
                                    <img src="https://picsum.photos/seed/trfvbg/630/670" className="img-fluid" alt="" />
                                    <div className="absolute-fill d-flex flex-column align-items-end z-index-2">
                                        <div className="w-100 mt-auto p-7"><h3 className="fw-normal lh-1-37 mb-4"></h3>
                                            <p className="lead opacity-100 mb-0"></p>
                                        </div>
                                    </div></div><div className="ps-8 pe-6 py-7 py-md-10">
                                    <h2 className="lh-1-37 mb-5">Stage 6: Testing</h2>
                                    <p className="lead opacity-60 lh-1-75 mb-6">We thoroughly tests the solution to ensure that it meets the requirements and works as intended.</p>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-6 mb-5">
                                <div className="ps-6 pe-8 pt-md-8 pb-7 pb-md-10">
                                    <h2 className="lh-1-37 mb-5">Stage 7: Deployment</h2>
                                    <p className="lead opacity-60 lh-1-75 mb-0">We deploy the solution to the customer's environment, making it available for use.</p></div>
                                <div className="position-relative text-white overlay overlay-solid" style={{ minHeight: "200px" }}>
                                    <img src="https://picsum.photos/seed/hjyunm/630/670" className="img-fluid" alt="" />
                                    <div className="absolute-fill d-flex flex-column align-items-end z-index-2">
                                        <div className="w-100 mt-auto p-7">
                                            <h3 className="fw-normal lh-1-37 mb-4"></h3>
                                            <p className="lead opacity-100 mb-0"></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="position-relative text-white overlay overlay-solid" style={{ minHeight: "200px" }}>
                                    <img src="https://picsum.photos/seed/trfvbg/630/670" className="img-fluid" alt="" />
                                    <div className="absolute-fill d-flex flex-column align-items-end z-index-2">
                                        <div className="w-100 mt-auto p-7"><h3 className="fw-normal lh-1-37 mb-4"></h3>
                                            <p className="lead opacity-100 mb-0"></p>
                                        </div>
                                    </div></div><div className="ps-8 pe-6 py-7 py-md-10">
                                    <h2 className="lh-1-37 mb-5">Stage 8: Maintenance and Support</h2>
                                    <p className="lead opacity-60 lh-1-75 mb-6">We provide ongoing maintenance and support for the solution, including fixing any bugs or issues that arise and adding new features as needed.</p>
                                </div>
                            </div>
                            <div className="col-md-12 text-center">
                                <Link to="/contact">
                                    <Button color="primary" className="rounded-pill main-button" size="lg">Get in touch</Button>
                                </Link>
                            </div>

                        </div>
                    </div>
                </div>
            </div> */}
        </div>
    )
}

export default Process