import React, { useEffect } from 'react'
import '../../../assets/css/Main.css'
import ProjectsItems from '../../mainsite/projectsItems.js'
import TextOnImage from '../textOnImage.js'
import image from '../../../assets/images/image7.jpg'

const Projects = () => {

    useEffect(() => {
        document.title = 'Projects | A proven track record delivering cost effective software solutions | Mobyte';

        // Create meta description
        const metaDescription = document.createElement('meta');
        metaDescription.setAttribute('name', 'description');
        metaDescription.content = 'Discover our portfolio of innovative software development projects crafted. From conceptualization to deployment, each project reflects our commitment to excellence and client satisfaction.';

        // Create meta keywords
        const metaKeywords = document.createElement('meta');
        metaKeywords.setAttribute('name', 'keywords');
        metaKeywords.content = 'Project portfolio, innovative projects, client success, project examples, industry expertise, customized solutions, project showcase, successful deployments, client satisfaction, excellence in execution';

        const metaRobots = document.createElement('meta');
        metaRobots.setAttribute('name', 'robots');
        metaRobots.content = 'index';
        document.head.appendChild(metaRobots);

        // Append both meta elements to the head
        document.head.appendChild(metaDescription);
        document.head.appendChild(metaKeywords);
        document.head.appendChild(metaKeywords);

        return () => {
            // Clean up added meta tags when component unmounts
            document.head.removeChild(metaDescription);
            document.head.removeChild(metaKeywords);
            document.head.removeChild(metaRobots);

        };
    }, []);

    const style = {
        border: "solid 1px lightgrey"
    }



    return (
        <div className="mb-5">
            <TextOnImage imageUrl={image} subheading={<span className="font-white">Projects</span>} fromHome={false} altText={"Laptop displaying interactive dashboard"} />
            <div className="container-large">

                <div className='heading-block'>
                    <h2 className={`content-heading font-darkteal `}>
                        A proven track record delivering&nbsp;<span className="font-orange emph-brush">cost effective software</span>&nbsp;solutions
                    </h2>
                </div>
                <ProjectsItems style={style} />
            </div>


        </div>
    )
}

export default Projects