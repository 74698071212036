import React, { useEffect } from 'react'
import '../../../assets/css/Main.css'
import '../../../assets/css/timeline.css'
import { DiReact, DiCode, DiNetmagazine } from 'react-icons/di'
import { AiOutlineUser, AiOutlineCloud, AiOutlineDatabase, AiOutlineCheck, AiOutlineLogin } from 'react-icons/ai'
import { Row } from 'reactstrap'
import TextOnImage from '../textOnImage.js'
import image from '../../../assets/images/about.jpg'
const About = () => {

    useEffect(() => {
        document.title = 'About us | Providing IT consultancy services for over 35 years | Mobyte';

        // Create meta description
        const metaDescription = document.createElement('meta');
        metaDescription.setAttribute('name', 'description');
        metaDescription.content = 'Professional software services provider that specializes in delivering fully managed solutions based on Microsoft .NET, React and JavaScript.';

        // Create meta keywords
        const metaKeywords = document.createElement('meta');
        metaKeywords.setAttribute('name', 'keywords');
        metaKeywords.content = 'IT services, technology solutions, company mission, values, expertise';

        const metaRobots = document.createElement('meta');
        metaRobots.setAttribute('name', 'robots');
        metaRobots.content = 'index';
        document.head.appendChild(metaRobots);

        // Append both meta elements to the head
        document.head.appendChild(metaDescription);
        document.head.appendChild(metaKeywords);
        document.head.appendChild(metaKeywords);

        return () => {
            // Clean up added meta tags when component unmounts
            document.head.removeChild(metaDescription);
            document.head.removeChild(metaKeywords);
            document.head.removeChild(metaRobots);

        };
    }, []);

    const subheading = (
        <span className="font-white">
            About us
        </span>
    )


    return (
        <div className="">
            {/* <div className="bg-teal p-5 mb-5">

                <h1>About Us</h1>
                <p className="main-subheading">Mobyte was established as an IT consultancy company in 1987, and since then we have provided a variety of software and business services on behalf of our clients.</p>
            </div> */}
            {/* <div className="bg-teal ps-5 pe-5 pt-3 pb-3 mb-5">
                <div className="main-heading">About Us</div>
                <p className="main-subheading">Mobyte was established as an IT consultancy company in 1987, and since then we have provided a variety of software and business services on behalf of our clients.</p>
            </div> */}

            {/* <Banner heading="About us" subheading="Mobyte was established as an IT consultancy company in 1987, and since then we have provided a variety of software and business services on behalf of our clients." /> */}
            <TextOnImage imageUrl={image} heading={"About us"} subheading={subheading} fromHome={false} altText={"Modern workspace"} />

            <div className="container-large">
                <div className='heading-block'>
                    <h2 className={`content-heading font-darkteal`}>
                        Providing&nbsp;<span className="header-brush">IT consultancy</span>&nbsp;services for over 35 years
                    </h2>
                </div>
                {/* <Row className="">
                    <div className="section-heading pb-3 ps-5" style={{ paddingLeft: "30px" }}>
                        Our Story
                    </div>
                </Row> */}


                <Row className="">
                    <ul className="timeline">
                        <li>
                            <div className="timeline-badge"><DiReact size={32} /></div>
                            <div className="timeline-panel">
                                <div className="timeline-heading">
                                    <h4 className="timeline-title">2024 - Mobyte website revamp</h4>
                                </div>
                                <div className="timeline-body">
                                    <p style={{ textAlign: "left" }} className="text-muted">We embarked on a transformative journey to revamp the Mobyte website, elevating every aspect of our online presence. Through meticulous design enhancements and SEO optimization, we've crafted an immersive digital space that not only reflects our brand ethos but also ensures seamless user engagement. Moreover, we've embraced our new brand colors, infusing vibrancy and cohesion into every pixel.</p>
                                </div>
                            </div>
                        </li>
                        <li className="timeline-inverted">
                            <div className="timeline-badge"><AiOutlineLogin size={23} /></div>
                            <div className="timeline-panel">
                                <div className="timeline-heading">
                                    <h4 className="timeline-title">2023 - Introducing the next generation of cards database</h4>
                                </div>
                                <div className="timeline-body">
                                    <p style={{ textAlign: "left" }} className="text-muted">
                                        We undertook a transformative journey to modernize the Cards Database, a cornerstone of aviation refueling card management. Our mission was clear: to transition from a static Access database to a dynamic web-based application, equipped with state-of-the-art technologies. This initiative involved phasing out outdated packages and developing a robust ASP.NET Core API for seamless database interaction. In the final stage, we revamped the user interface into a dynamic React Single Page Application (SPA), integrating advanced features like OAuth for secure Single Sign-On (SSO) authentication. The result? A reimagined Cards Database that sets new standards in efficiency, usability, and security, empowering airfields worldwide to operate at their best.
                                    </p>
                                </div>
                            </div>
                        </li>
                        <li>
                            <div className="timeline-badge"><DiReact size={32} /></div>
                            <div className="timeline-panel">
                                <div className="timeline-heading">
                                    <h4 className="timeline-title">2022 - Unveiling the new Mobyte website</h4>
                                </div>
                                <div className="timeline-body">
                                    <p style={{ textAlign: "left" }} className="text-muted">We proudly unveiled the revamped Mobyte website, leveraging the latest technologies to deliver an unparalleled user experience. Our team embarked on a comprehensive redevelopment journey, harnessing the power of ASP.NET Core, React, and MSAL (formerly ASP.NET Framework). This strategic overhaul not only enhances the website's performance and responsiveness but also reflects our commitment to innovation and user-centric design.</p>
                                </div>
                            </div>
                        </li>
                        <li className="timeline-inverted">
                            <div className="timeline-badge"><AiOutlineLogin size={23} /></div>
                            <div className="timeline-panel">
                                <div className="timeline-heading">
                                    <h4 className="timeline-title">2022 - Streamlining authentication with single sign-On (SSO)</h4>
                                </div>
                                <div className="timeline-body">
                                    <p style={{ textAlign: "left" }} className="text-muted">We took a significant step forward by implementing Single Sign-On (SSO) authentication across multiple applications. Leveraging the power of the Microsoft Authentication Library (MSAL) for React, we seamlessly integrated SSO functionality, providing users with a unified authentication experience. This implementation not only enhances security but also simplifies access management, improving user productivity and experience across our digital ecosystem.</p>
                                </div>
                            </div>
                        </li>
                        <li>
                            <div className="timeline-badge"><DiReact size={32} /></div>
                            <div className="timeline-panel">
                                <div className="timeline-heading">
                                    <h4 className="timeline-title">2021 - RADAR application</h4>
                                </div>
                                <div className="timeline-body">
                                    <p style={{ textAlign: "left" }} className="text-muted">A revolutionary document control platform designed and developed by our team. RADAR represents a significant leap forward in streamlining the process of authoring and approving technical, grammatical, and regulatory changes across various documents. This innovative digital solution empowers businesses with simplified workflows, ensuring efficiency and accuracy in managing document revisions and approvals.</p>
                                </div>
                            </div>
                        </li>
                        <li className="timeline-inverted">
                            <div className="timeline-badge"><DiNetmagazine size={35} /></div>
                            <div className="timeline-panel">
                                <div className="timeline-heading">
                                    <h4 className="timeline-title">2021 - CADOPS redevelopment</h4>
                                </div>
                                <div className="timeline-body">
                                    <p style={{ textAlign: "left" }} className="text-muted">We initiated a multi-phased project to transform the existing CADOPS application into a cutting-edge digital web application. The initial phases focused on removing the system's offline capabilities and developing an ASP.NET Core API to interact with the central SQL server database. The latest phase involved redeveloping the front-end into a dynamic React Single Page Application (SPA), incorporating OAuth for secure Single Sign-On (SSO) authentication. This redevelopment has significantly enhanced the application's functionality, usability, and security.</p>
                                </div>
                            </div>
                        </li>
                        <li>
                            <div className="timeline-badge"><AiOutlineCloud size={27} /></div>
                            <div className="timeline-panel">
                                <div className="timeline-heading">
                                    <h4 className="timeline-title">2018 - Cloud migration initiative</h4>
                                </div>
                                <div className="timeline-body">
                                    <p style={{ textAlign: "left" }} className="text-muted">We spearheaded a multi-phased project to migrate numerous Windows 2003 on-premise servers to the AWS cloud. Our team meticulously planned and executed this complex transition, ensuring a seamless migration process. This strategic move to the cloud enhanced our infrastructure's scalability, reliability, and efficiency, positioning us at the forefront of modern technological advancements.</p>
                                </div>
                            </div>
                        </li>
                        <li className="timeline-inverted">
                            <div className="timeline-badge"><AiOutlineDatabase size={23} /></div>
                            <div className="timeline-panel">
                                <div className="timeline-heading">
                                    <h4 className="timeline-title">2012 - CADOPS project launch</h4>
                                </div>
                                <div className="timeline-body">
                                    <p style={{ textAlign: "left" }} className="text-muted">We embarked on the development of the CADOPS project, a cutting-edge training planning and evaluation application. Designed as a client-server implementation with offline capabilities, CADOPS revolutionized the way organizations manage employee training. It enabled users to set up comprehensive training programs, produce standardized documentation and assessment papers, and submit and report on training results. This powerful tool was instrumental in managing risk, enhancing personal safety, and developing staff competency.</p>
                                </div>
                            </div>
                        </li>
                        <li>
                            <div className="timeline-badge"><AiOutlineUser size={23} /></div>
                            <div className="timeline-panel">
                                <div className="timeline-heading">
                                    <h4 className="timeline-title">2011 - Lawrence Whale joins the team</h4>
                                </div>
                                <div className="timeline-body">
                                    <p style={{ textAlign: "left" }} className="text-muted">We welcomed Lawrence Whale to our team. With a BSc(Hons) degree in Business, Lawrence brought fresh insights and expertise to our company. As a Lead Consultant, he took on the crucial role of coordinating a variety of upcoming IT projects, driving our growth and innovation forward.</p>
                                </div>
                            </div>
                        </li>
                        <li className="timeline-inverted">
                            <div className="timeline-badge"><DiCode size={32} /></div>
                            <div className="timeline-panel">
                                <div className="timeline-heading">
                                    <h4 className="timeline-title">1998 - Intercompany website</h4>
                                </div>
                                <div className="timeline-body">
                                    <p style={{ textAlign: "left" }} className="text-muted">We took a significant leap forward by launching our first internet-facing website. This groundbreaking platform revolutionized the way our client handled intercompany transactions, providing a comprehensive solution for reconciliation and streamlining their operations.</p>
                                </div>
                            </div>
                        </li>
                        <li>
                            <div className="timeline-badge"><AiOutlineCheck size={20} /></div>
                            <div className="timeline-panel">
                                <div className="timeline-heading">
                                    <h4 className="timeline-title">1987 - Company formed</h4>
                                </div>
                                <div className="timeline-body">
                                    <p style={{ textAlign: "left" }} className="text-muted">In an era long before the advent of the internet, email, and mobile phones, we embarked on our journey. Our company was established with a pioneering spirit, dedicated to developing cutting-edge Mainframe and PC software. Since those early days, we have continually evolved, embracing new technologies and innovations to serve our clients better.</p>
                                </div>
                            </div>
                        </li>
                    </ul>
                </Row>

            </div>
        </div>
    )
}

export default About