import React, { useEffect, useState } from 'react';
import { Button } from 'reactstrap'
import { Link } from 'react-router-dom'
import '../../assets/css/textonImage.css'

const ImageWithButton = ({ imageUrl, heading, subheading, fromHome, altText }) => {


    const [isVisible, setIsVisible] = useState(false);

    useEffect(() => {
        setIsVisible(true);
    }, []);

    return (
        <div className="image-container">

            {/* Your content */}
            <div className={`fade-in ${isVisible ? 'visible' : ''}`}>
                <h1 className={fromHome ? "header-style" : "header-style-sub"} >{subheading}</h1>
                {fromHome && (
                    <>
                        <h2 className={fromHome ? "subheader-style pt-5 mt-4" : "subheader-style-sub pt-5 mt-4"} >{heading}</h2>
                        <div className="header-button" style={{ marginTop: "110px" }}>
                            <Link to='/contact'>
                                <Button className="main-button btn-steel-blue ">
                                    Contact us
                                </Button>
                            </Link>
                        </div>
                    </>
                )
                }
            </div>


            {/* Dark overlay */}
            <div className="overlay-style"></div>
            <img src={imageUrl} alt={altText} className={fromHome ? "image-style" : "image-style-sub"} />


            {/* Image */}

        </div >
    );
};

export default ImageWithButton;
