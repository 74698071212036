import React from 'react'
import { useEffect, useState, useCallback, useRef } from 'react';
import { AiOutlineInteraction } from 'react-icons/ai'
import '../../../assets/css/Main.css'
import { Col, Row, CardHeader, CardBody, Button, Card, Input, InputGroup, Tooltip } from 'reactstrap';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-balham.css';
import base from '../../../services/BaseService.js'
import Spinner from '../../shared/loading.js'
import EditModal from './tasksModal.js'
import Select from 'react-select'
import remove from '../../../assets/icons/remove24.svg';
import removeLrg from '../../../assets/icons/remove32.svg';
import download from '../../../assets/icons/download24.svg';
import ifilter from '../../../assets/icons/filter24.svg';
import ifilterFill from '../../../assets/icons/filterfill24.svg';
const Projects = () => {

    const gridRef = useRef();
    const [saveItem, setSaveItem] = useState();
    const [budgetID, setBudgetID] = useState();
    const [gridDefaultColDef, setGridDefaultColDef] = useState([]);
    const [budgetColDef, setBudgetColDef] = useState([]);
    const [showModal, setShowModal] = useState(false)
    const [toggleModal, setToggleModal] = useState(false)
    const [expLoading, setExpLoading] = useState(true);
    const [data, setData] = useState([]);
    const [originData, setOriginData] = useState([]);
    const [filter1, setFilter1] = useState(['Active']);
    const [filter2, setFilter2] = useState([]);
    const [filter3, setFilter3] = useState('');
    const [filter4, setFilter4] = useState('');
    const [inputValue, setInputValue] = useState({ value: "Active", label: 'Active' });
    const [inputValue1, setInputValue1] = useState([]);
    const [inputValue2, setInputValue2] = useState([]);
    const [tooltipOpen, setTooltipOpen] = useState(false);
    const toggle = () => setTooltipOpen(!tooltipOpen);
    const [filter, setFilter] = useState(true);
    const [showFilter, setShowFilter] = useState(false);


    const options1 = [
        { value: "Active", label: 'Active' },
        { value: "Completed", label: 'Completed' },
        { value: "Hidden", label: 'Hidden' },
    ]

    const options2 = [
        { value: "Y", label: 'Yes' },
        { value: "N", label: 'No' },
    ]

    const options3 = [
        { value: "CC_LW", label: 'CC_LW' },
        { value: "CC_MW", label: 'CC_MW' },
    ]


    const initGridVar = useCallback(() => {
        setGridDefaultColDef({
            flex: 1,
            minWidth: 100,
            sortable: true,
            filter: true,
            resizable: true,
            editable: false,
            sortingOrder: ["asc", "desc"],
        });

        setBudgetColDef([
            {
                headerName: "url",
                field: "url",
                suppressMovable: true,
                maxWidth: 350
            },
            {
                headerName: "Task",
                field: "taskName",
                suppressMovable: true,
                maxWidth: 250
            },
            {
                headerName: "Client",
                field: "organisationName",
                suppressMovable: true,
                maxWidth: 250
            },
            {
                headerName: "Project",
                field: "projectName",
                suppressMovable: true,
                maxWidth: 250
            },
            {
                headerName: "IsBillable",
                field: "isBillable",
                suppressMovable: true,
                maxWidth: 100
            },
            {
                headerName: "BillingRate",
                field: "billingRate",
                suppressMovable: true,
                filter: 'agNumberColumnFilter',
                type: 'numericColumn',
                maxWidth: 100
            },
            {
                headerName: "Status",
                field: "status",
                suppressMovable: true,
                maxWidth: 100
            },
            {
                headerName: "CostCentre",
                field: "costCentre",
                suppressMovable: true,
                maxWidth: 100
            },
            {
                headerName: "ADO",
                field: "urlADO",
                suppressMovable: true,
                maxWidth: 300
            },
            {
                headerName: "Approved",
                field: "approved",
                suppressMovable: true,
                maxWidth: 100
            },
            {
                headerName: "id",
                field: "id",
                suppressMovable: true,
                hide: true,
                type: 'numericColumn',

            },
        ]
        );
    }, []);

    const selectChange = (e, id) => {
        // console.log(e, id.name)
        var fName = id.name
        if (fName === 'drpStatus') {
            setFilter1(Array.isArray(e) ? e.map(x => x.value) : []);
            setInputValue(e)
        }
        if (fName === 'drpYORN') {
            setInputValue(e.value);
            setFilter2(Array.isArray(e) ? e.map(x => x.value) : []);
            setInputValue1(e)
        }

        if (fName === 'drpCC') {
            setInputValue2(e.value);
            setFilter4(Array.isArray(e) ? e.map(x => x.value) : []);
            setInputValue2(e)
        }
    }

    useEffect(() => {
        const filterData = () => {
            var xData = originData;

            if (filter1.length > 0) {
                xData = xData.filter(f => filter1.some(item => item.includes(f.status)));
                setFilter(true);
            }

            if (filter2.length > 0) {
                xData = xData.filter(f => filter2.some(item => item.includes(f.approved)));
                setFilter(true);
            }

            if (filter4.length > 0) {
                xData = xData.filter(f => filter4.some(item => item.includes(f.costCentre)));
                setFilter(true);
            }


            if (filter3 !== '') {
                xData = xData.filter(f => f.taskName.toUpperCase().includes(filter3.toUpperCase()) || f.organisationName.toUpperCase().includes(filter3.toUpperCase()) || f.projectName.toUpperCase().includes(filter3.toUpperCase()));
                setFilter(true);

            }

            if (filter1.length === 0 && filter3 === '' && filter2.length === 0 && filter4.length === 0) {
                setFilter(false);
            }

            setData(xData);

        }
        filterData();
    }, [filter1, filter2, filter3, filter4, originData]);



    const handleChange = (e) => {
        // console.log(e.target.value)
        var searchInput = e.target.value;
        if (searchInput !== '') {
            setFilter3(searchInput);
        }
        else {
            setFilter3('');
        }
    }

    const onBtnExport = useCallback(() => {
        gridRef.current.api.exportDataAsCsv();
    }, []);

    const closeFilter = () => {
        setTooltipOpen(false);
        setShowFilter(false);
        setFilter(true);
        setData(originData);
        setInputValue({ value: "Active", label: 'Active' });
        setFilter1(['Active']);
        setFilter2([]);
        setFilter4([]);
        setFilter3('');
    }

    const openModal = () => {
        setShowModal(!showModal)
    }

    const closeModal = () => {
        setShowModal(showModal => false)
        // setExpLoading(true)
        setToggleModal(!toggleModal)
    }

    useEffect(() => {
        initGridVar();
    }, [initGridVar]);

    useEffect(() => {
        const getContacts = async () => {
            await base.getAll("ManageData/tasks").then((res) => {
                setData(res);
                setOriginData(res);
            });
        }
        getContacts();
        setTimeout(() => {
            setExpLoading(false)
        }, [1000])

    }, [saveItem])

    const onSelectionChanged = () => {
        const selectedRows = gridRef.current.api.getSelectedRows();
        if (selectedRows.length === 1) {
            setBudgetID(selectedRows[0].id);
            openModal();
        }

    };

    return (
        <>

            <CardHeader className="pt-3 pb-3">
                <Row>
                    <Col xs="12" sm="9" className="page-header">
                        <AiOutlineInteraction size={30} className="mb-1" color="#8bc5c5" />&nbsp;&nbsp;
                        <span className="section-heading">Task Enrichment</span>
                    </Col>
                    {/* <span > */}
                    <Col xs="12" sm="3" className="text-end pe-4">
                        <Button size="sm" color="light border-dark" className="btn-text me-1" style={{ height: "38px" }} onClick={() => setShowFilter(!showFilter)}>
                            {filter ?
                                <img src={ifilterFill} alt="filter applied" />
                                :
                                <img src={ifilter} alt="filter" />

                            }
                        </Button>
                        <Button size="sm" color="light border-dark" className="btn-text me-1" style={{ height: "38px" }} onClick={() => onBtnExport()}>
                            <img src={download} alt="download" />
                        </Button>
                    </Col>
                </Row>
            </CardHeader>
            <div className="container-fluid">
                {showFilter &&
                    <Card className="mt-3 ms-3 me-3">
                        <CardBody style={{ backgroundColor: "#F2F2F2" }}>

                            <div className="row align-items-center">
                                <Col xs="12" sm="12" lg="3" className="mb-2">
                                    <InputGroup>
                                        <Input type="text" value={filter3} className="input-text-filter" name="keyword" id="keyword" placeholder="Filter by keyword" onChange={handleChange} />
                                        <Button size="sm" color="" className="btn-text" onClick={() => setFilter3('')}>
                                            <img src={remove} alt="remove" />
                                        </Button>
                                    </InputGroup>
                                </Col>
                                <Col xs="12" sm="12" lg="3" className="mb-2">
                                    <Select className='input-text-filter' closeMenuOnSelect={false} options={options1} isMulti={true} onChange={selectChange} name="drpStatus"
                                        defaultValue={inputValue}
                                        value={inputValue}

                                        placeholder={"Status..."} />
                                </Col>
                                <Col xs="12" sm="12" lg="2" className="mb-2">
                                    <Select className='input-text-filter' closeMenuOnSelect={false} options={options2} isMulti={true} onChange={selectChange} name="drpYORN"
                                        defaultValue={inputValue1}
                                        value={inputValue1}

                                        placeholder={"Approved..."} />
                                </Col>
                                <Col xs="12" sm="12" lg="2" className="mb-2">
                                    <Select className='input-text-filter' closeMenuOnSelect={false} options={options3} isMulti={true} onChange={selectChange} name="drpCC"
                                        defaultValue={inputValue2}
                                        value={inputValue2}

                                        placeholder={"Cost Centre..."} />
                                </Col>
                                <Col className='text-end'>
                                    <Button size="sm" color="" className="btn-text" id="TooltipExample" onClick={() => closeFilter(false)}>
                                        <img src={removeLrg} alt="remove" />
                                        <Tooltip
                                            isOpen={tooltipOpen}
                                            target="TooltipExample"
                                            toggle={toggle}
                                            placement={'bottom'}
                                        >
                                            Close and reset filters
                                        </Tooltip>
                                    </Button>
                                </Col>

                            </div>
                        </CardBody>
                    </Card>


                }
                <Row >
                    <Col xs="12">
                        {expLoading ?
                            <CardBody style={{ height: "750px" }}>
                                <div className="row align-items-center">
                                    <div className="text-center" >
                                        <Spinner />
                                    </div>
                                </div>
                            </CardBody>
                            :
                            <>

                                <CardBody style={{ height: "680px" }}>
                                    <div className="ag-theme-balham" style={{ height: "100%", width: "100%" }} >
                                        <AgGridReact
                                            ref={gridRef}
                                            rowData={data}
                                            columnDefs={budgetColDef}
                                            overlayNoRowsTemplate="No Records Found"
                                            defaultColDef={gridDefaultColDef}
                                            pagination={true}
                                            rowSelection={'single'}
                                            paginationPageSize={20}
                                            onRowDoubleClicked={onSelectionChanged}
                                        >
                                        </AgGridReact>
                                    </div>
                                </CardBody>
                            </>

                        }

                    </Col>

                </Row >
            </div>
            {showModal && <EditModal id={budgetID} closeModal={closeModal} showModalForm={true} setSaveItem={setSaveItem} />}

        </>
    )
}

export default Projects
