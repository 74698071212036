import React from 'react';
import { MsalProvider } from "@azure/msal-react";
import { msalConfig } from "./authconfig.js";
import { PublicClientApplication } from "@azure/msal-browser";
import App from './App.js'

const MsalLoader = () => {

    const msalInstance = new PublicClientApplication(msalConfig);

    return (
        <MsalProvider instance={msalInstance}>
            <App />
        </MsalProvider>

    );
}

export default MsalLoader;
