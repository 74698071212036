import React from 'react'
// import logo from './logo.svg';
import { useEffect, useState, useCallback, useRef } from 'react';
import { AiOutlineUser } from 'react-icons/ai'
// import '../App.css';
import '../../../assets/css/Main.css'
import { Col, Row, CardHeader, CardBody } from 'reactstrap';
// import DataTable from 'react-data-table-component';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-balham.css';
import base from '../../../services/BaseService.js'
import Spinner from '../../shared/loading.js'
import EditModal from './usersModal.js'

const Projects = () => {

    const gridRef = useRef();
    const [budgetData, setBudgetData] = useState([]);
    const [saveItem, setSaveItem] = useState();
    const [budgetID, setBudgetID] = useState();
    const [gridDefaultColDef, setGridDefaultColDef] = useState([]);
    const [budgetColDef, setBudgetColDef] = useState([]);
    const [showModal, setShowModal] = useState(false)
    const [toggleModal, setToggleModal] = useState(false)
    const [expLoading, setExpLoading] = useState(true);

    const initGridVar = useCallback(() => {
        setGridDefaultColDef({
            flex: 1,
            minWidth: 100,
            sortable: true,
            filter: true,
            resizable: true,
            editable: false,
            sortingOrder: ["asc", "desc"],
        });

        setBudgetColDef([
            {
                headerName: "First Name",
                field: "firstName",
                // cellClass: "alignCenter widthhhh",
                suppressMovable: true,
                maxWidth: 200
            },
            {
                headerName: "Last Name",
                field: "lastName",
                // cellClass: "alignCenter widthhhh",
                suppressMovable: true,
                maxWidth: 200
            },
            {
                headerName: "Cost Centre",
                field: "costCentre",
                // cellClass: "alignCenter widthhhh",
                suppressMovable: true,
                maxWidth: 125
            },
            {
                headerName: "UPN",
                field: "upn",
                // cellClass: "alignCenter widthhhh",
                suppressMovable: true,
                maxWidth: 350
            },
            {
                headerName: "Email",
                field: "email",
                // cellClass: "alignCenter widthhhh",
                suppressMovable: true,
                maxWidth: 350
            },
            {
                headerName: "User Level",
                field: "userLevel",
                // cellClass: "alignCenter widthhhh",
                suppressMovable: true,
                maxWidth: 125
            },
            {
                headerName: "url",
                field: "url",
                // cellClass: "alignCenter widthhhh",
                suppressMovable: true,
                maxWidth: 350
            },
            {
                headerName: "Last Login",
                field: "lastLogOn",
                // cellClass: "alignCenter widthhhh",
                suppressMovable: true,
                maxWidth: 200
            },
            {
                headerName: "id",
                field: "idUser",
                // hidden: true,
                suppressMovable: true,
                hide: true,
                // filter: 'agNumberColumnFilter',
                type: 'numericColumn',
                // setColumnVisible: false,
                // valueFormatter: currencyFormatter
            },
        ]
        );
    }, []);

    const openModal = () => {
        setShowModal(!showModal)
        // //console.log(showModal)
    }

    const closeModal = () => {
        setShowModal(showModal => false)
        // setExpLoading(true)
        setToggleModal(!toggleModal)
    }

    useEffect(() => {
        initGridVar();
    }, [initGridVar]);

    useEffect(() => {
        const getContacts = async () => {
            await base.getAll("ManageData/users").then((res) => {
                setBudgetData(res);
            });
        }
        getContacts();
        setTimeout(() => {
            setExpLoading(false)
        }, [1000])

    }, [saveItem])

    const onSelectionChanged = () => {
        const selectedRows = gridRef.current.api.getSelectedRows();
        if (selectedRows.length === 1) {
            setBudgetID(selectedRows[0].idUser);

            openModal();
            // //console.log(selectedRows[0].id)
            // setTimeout(() => {
            //     //console.log(budgetID);
            // }, [5000])

        }
        // document.querySelector('#selectedRows').innerHTML =
        //     selectedRows.length === 1 ? selectedRows[0].id : '';
    };

    return (
        <>

            <CardHeader className="pt-3 pb-3">
                <Row>
                    <Col xs="12" className="page-header">
                        <AiOutlineUser size={30} className="mb-1" color="#8bc5c5" />&nbsp;&nbsp;
                        <span className="section-heading">User Enrichment</span>
                    </Col>

                </Row>
            </CardHeader>
            <div className="container-fluid">
                <Row >
                    <Col xs="12">
                        {expLoading ?
                            <CardBody style={{ height: "750px" }}>
                                <div className="row align-items-center">
                                    <div className="text-center" >
                                        <Spinner />
                                    </div>
                                </div>
                            </CardBody>
                            :
                            <>
                                {/* <div className="example-header">
                                Selection:
                                <span id="selectedRows"></span>
                            </div> */}

                                <CardBody style={{ height: "680px" }}>
                                    <div className="ag-theme-balham" style={{ height: "100%", width: "100%" }} >
                                        <AgGridReact
                                            ref={gridRef}
                                            rowData={budgetData}
                                            columnDefs={budgetColDef}
                                            overlayNoRowsTemplate="No Records Found"
                                            defaultColDef={gridDefaultColDef}
                                            pagination={true}
                                            // onGridReady={onGridReady}
                                            rowSelection={'single'}
                                            // rowSelection={'single'}
                                            paginationPageSize={20}
                                            // gridOptions={gridOptions}
                                            onRowDoubleClicked={onSelectionChanged}
                                        // onGridReady={onGridReady}
                                        // onSelectionChanged={gridRef.current.api.getSelectedRows()}
                                        // onRowDoubleClicked={() => //console.log('Row double clicked')}
                                        // onRowDoubleClicked=(()=> editRow())
                                        >
                                        </AgGridReact>
                                    </div>
                                </CardBody>
                            </>

                        }

                    </Col>

                </Row >
            </div>
            {showModal && <EditModal id={budgetID} closeModal={closeModal} showModalForm={true} setSaveItem={setSaveItem} />}
            {/* {showModal && <BudgetModal id={budgetID} openModal={openModal} closeModal={closeModal} showModalForm={true} />} */}

        </>
    )
}

export default Projects
