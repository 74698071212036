import React, { useEffect, useState } from 'react';
// import { useParams } from 'react-router-dom';
import TextOnImage from './textOnImage.js'
import image from '../../assets/images/03-thumbnail.jpg'
import { Row, Col } from 'reactstrap'
import { Link } from 'react-router-dom';
import { Button } from 'reactstrap';
import { IoArrowBack, IoConstructOutline } from "react-icons/io5";

export default function ProjectDetail() {
    // const { projectID } = useParams();

    const [isVisible, setIsVisible] = useState(false);

    useEffect(() => {
        setIsVisible(true);
    }, []);



    // const getBackgroundColor = (index) => {
    //     const colors = ['#fff', '#ffc266', '#003333'];
    //     return colors[index % colors.length];
    // };

    return (
        <>
            <TextOnImage imageUrl={image} heading={"Coming soon"} subheading={<span >
                <span className="font-white">Coming soon</span>&nbsp;&nbsp;
                {/* that empower your business */}
            </span>} fromHome={false} altText={"Laptop displaying code editor on desk"} />
            <Row className="container-large">
                <Col>
                    <Link to="/projects" >
                        <Button className="mt-3 btn-white">
                            <div className="d-flex">
                                <div >
                                    <IoArrowBack size={25} />

                                </div>
                                &nbsp;&nbsp;
                                <div style={{ paddingTop: "1px" }}>Projects</div>
                            </div>
                        </Button>

                    </Link>
                </Col>
            </Row>
            <Row className="container-large mt-5">

                <>
                    <Col xs="4" className={`text-center block-left-scroll ${isVisible ? 'visible' : ''}`}>
                        <IoConstructOutline className='icon  mb-3' />
                    </Col>
                    <Col className={`block-right-scroll ${isVisible ? 'visible' : ''}`}>
                        <h2 className={`content-heading font-darkteal`}>
                            <span >{"Our project pages are still under construction"}</span>
                        </h2>
                        <p className={`content-body font-darkteal`}>{"Please check back soon to find out more about our success stories."}</p>
                    </Col>

                </>
            </Row>

        </>


    );
}
