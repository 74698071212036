import React from 'react'
// import logo from './logo.svg';
import { useEffect, useState } from 'react';
// import '../App.css';
import '../../../assets/css/Main.css'
import { Col, Row, Button, Label, Input, Modal, ModalBody, ModalHeader, FormGroup, ModalFooter } from 'reactstrap';
// import DataTable from 'react-data-table-component';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-balham.css';
import base from '../../../services/BaseService.js'
import LoadSpinner from '../../shared/loading.js'

const TasksModal = ({ id, closeModal, showModalForm, setSaveItem }) => {

    const [field01, setField01] = useState()
    const [field02, setField02] = useState()
    const [field03, setField03] = useState()
    const [field04, setField04] = useState()
    const [loading, setLoading] = useState(false)
    const [showModal, setShowModal] = useState(showModalForm)
    const updateData = {};

    const toggleModal = () => {
        closeModal()
        setShowModal(showModal => !showModal);
    }
    const deleteItem = () => {
        updateData.name = field01;
        updateData.email = field02;
        updateData.comment = field03;
        // updateData.period = field04;
        // updateData.value = field05;
        // updateData.reason1 = field06;
        // updateData.reason2 = field08;

        base.update("Report/contactform/del/" + id, updateData).then((res) => {
            toggleModal()
            setSaveItem(new Date().getTime());
        })
        // alert("Save!")
    }

    // const updateItem = () => {
    //     updateData.FirstName = field01;
    //     updateData.LastName = field02;
    //     updateData.url = field03;
    //     updateData.UPN = field04;
    //     updateData.Email = field05;
    //     updateData.UserLevel = field06;
    //     updateData.CostCentre = field08;

    //     base.update("ManageData/user/upd/" + id, updateData).then((res) => {
    //         toggleModal()
    //         setSaveItem(new Date().getTime());
    //     })
    //     // alert("Save!")
    // }

    // const handleChange = (e) => {
    //     // //console.log("hangleChange", e.target.name)
    //     if (e.target.name === 'name') {
    //         setField01(e.target.value);
    //         // setDescriptionRemaining(e.target.value.length);
    //     }
    //     if (e.target.name === 'lastName') {
    //         setField02(e.target.value);
    //         // setDescriptionRemaining(e.target.value.length);
    //     }
    //     if (e.target.name === 'url') {
    //         setField03(e.target.value);
    //         // setDescriptionRemaining(e.target.value.length);
    //     }
    //     if (e.target.name === 'upn') {
    //         setField04(e.target.value);
    //         // //console.log(e.target.value);
    //         // setDescriptionRemaining(e.target.value.length);
    //     }
    //     if (e.target.name === 'email') {
    //         setField05(e.target.value);
    //         // //console.log(e.target.value);
    //         // setDescriptionRemaining(e.target.value.length);
    //     }
    //     if (e.target.name === 'userLevel') {
    //         setField06(e.target.value);
    //         // //console.log(e.target.value);
    //         // setDescriptionRemaining(e.target.value.length);
    //     }
    //     if (e.target.name === 'lastLogOn') {
    //         setField07(e.target.value);
    //         // //console.log(e.target.value);
    //         // setDescriptionRemaining(e.target.value.length);
    //     }
    //     if (e.target.name === 'costCentre') {
    //         setField08(e.target.value);
    //         // //console.log(e.target.value);
    //         // setDescriptionRemaining(e.target.value.length);
    //     }

    //     document.getElementById(e.target.id).style.border = '1px solid #cecece';
    // }

    useEffect(() => {
        const getData = () => {
            // if (editExisting) {
            // setDisableUPN(dU => true)
            const getFormData = async () => {
                await base.getAll("Report/contactform/" + id).then((res) => {
                    setField01(res.name);
                    setField02(res.email);
                    setField03(res.comment);
                    setField04(res.up_date)
                    setLoading(false)
                })
            }

            getFormData();
            // }
            // else {
            //     // setDisableUPN(dU => false)
            //     setLoading(false)
            // }
        }
        getData()
    }, [id])


    return (
        <>
            <Modal isOpen={showModal} size="xl" toggle={toggleModal} backdrop="static">
                <ModalHeader className="bg-modal" toggle={toggleModal}><div className="page-header">Contact Message</div></ModalHeader>
                {loading ?
                    <LoadSpinner />
                    :
                    <>
                        <ModalBody>
                            <FormGroup>
                                <Row className="mb-2 align-items-center">
                                    <Col md="3" lg="2">
                                        <Label className="label-text" >Name</Label>
                                    </Col>
                                    <Col md="9" lg="10">
                                        <Input type="text" disabled className="input-text-locked" name="name" id="name" placeholder="" defaultValue={field01} />
                                    </Col>
                                </Row>
                                <Row className="mb-2 align-items-center">
                                    <Col md="3" lg="2">
                                        <Label className="label-text">Email</Label>
                                    </Col>
                                    <Col md="9" lg="10">
                                        <Input type="text" disabled className="input-text-locked" name="email" id="email" placeholder="" defaultValue={field02} />
                                    </Col>
                                </Row>
                                <Row className="mb-2 align-items-center">
                                    <Col md="3" lg="2">
                                        <Label className="label-text">Message</Label>
                                    </Col>
                                    <Col md="9" lg="10">
                                        <textarea id="message" disabled name="message" style={{ minHeight: "135px" }} className="form-control input-text-locked" rows="4" value={field03}></textarea>

                                        {/* <Input type="text" className="input-text-locked" name="comment" id="comment" placeholder="" defaultValue={field03} /> */}
                                    </Col>
                                </Row>
                                <Row className="mb-2 align-items-center">
                                    <Col md="3" lg="2">
                                        <Label className="label-text">Submitted Date</Label>
                                    </Col>
                                    <Col md="9" lg="10">
                                        <Input type="text" disabled className="input-text-locked" name="update" id="update" placeholder="" defaultValue={field04} />
                                    </Col>
                                </Row>
                            </FormGroup>
                        </ModalBody>
                        <ModalFooter>
                            <Button color="success" size="sm" className="btn-text b-width" onClick={() => deleteItem()}>Delete</Button>
                            {/* <Button color="success" size="sm" className="btn-text b-width" onClick={() => updateItem()}>Save</Button> */}
                            <Button color="secondary" size="sm" className="btn-text b-width" onClick={toggleModal}>Close</Button>
                        </ModalFooter>
                    </>
                }
            </Modal>
        </>
    )
}

export default TasksModal
