import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useMsal } from "@azure/msal-react";
import { InteractionStatus } from '@azure/msal-browser';
import Spinner from './components/shared/loading.js'
import PrivateRoutes from './components/shared/PrivateRoute.js'
import ReactGA from 'react-ga4';
import Cookies from 'universal-cookie';

const App = () => {
  const { inProgress } = useMsal();
  const [isBrowser, setIsBrowser] = React.useState(false);

  var cookies = new Cookies();
  const accepted = cookies.get("cookiesAccepted");

  useEffect(() => {
    setIsBrowser(true);
  }, []);

  useEffect(() => {
    if (accepted === "accept" && !ReactGA.default.isInitilized) {
      ReactGA.default.initialize('G-B6DJJKMSBP');
    }
  }, [accepted]);

  const location = useLocation();
  useEffect(() => {
    if (accepted === "accept") {
      ReactGA.default.send({ hitType: 'pageview', page: location.pathname + location.search });
    }
  }, [location, accepted]);

  return (
    <>
      {isBrowser && inProgress !== InteractionStatus.None ?
        <div className="container">
          <div style={{ paddingTop: "100px" }} className="text-center">
            <Spinner />
          </div>
        </div>
        :
        <PrivateRoutes />
      }
    </>
  );
}

export default App;
