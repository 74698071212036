import React, { useEffect } from 'react'
import '../../../assets/css/Main.css'
import { Row, Col, Card } from 'reactstrap'
import imgT4 from '../../../assets/images/04-thumbnail.jpg'
import simg_LW from '../../../assets/images/profile.jpg'
import { Link } from 'react-router-dom'
import TextOnImage from '../textOnImage.js'
import image from '../../../assets/images/cables.jpg'

const Blog = () => {

    useEffect(() => {
        document.title = 'Blog | Mobyte';

        // Create meta description
        const metaDescription = document.createElement('meta');
        metaDescription.setAttribute('name', 'description');
        metaDescription.content = 'Explore insightful articles and expert opinions on the latest software development trends, tips, and best practices.';

        // Create meta keywords
        const metaKeywords = document.createElement('meta');
        metaKeywords.setAttribute('name', 'keywords');
        metaKeywords.content = 'Software blog, Software development tips';

        const metaRobots = document.createElement('meta');
        metaRobots.setAttribute('name', 'robots');
        metaRobots.content = 'index';
        document.head.appendChild(metaRobots);

        // Append both meta elements to the head
        document.head.appendChild(metaDescription);
        document.head.appendChild(metaKeywords);
        document.head.appendChild(metaKeywords);

        return () => {
            // Clean up added meta tags when component unmounts
            document.head.removeChild(metaDescription);
            document.head.removeChild(metaKeywords);
            document.head.removeChild(metaRobots);

        };
    }, []);

    const subheading = (
        <span className="font-white">
            Blog
        </span>
    )

    return (
        <div className="mb-5">
            {/* <div className="bg-teal p-5 mb-5">
                <h1>Blog</h1>
                <p className="main-subheading">Stay connected with the latest updates and tutorials
                </p>
            </div> */}

            <TextOnImage imageUrl={image} heading={"About us"} subheading={subheading} fromHome={false} altText={"Connected cables"} />

            <div className="container-large">
                <div className='heading-block'>
                    <h2 className={`content-heading font-darkteal`}>
                        Stay updated with the latest in tech and tutorials from &nbsp;<span className="header-brush">IT experts</span>&nbsp;
                    </h2>
                </div>
                <Row className="pb-5">
                    <div className="">
                        <div className="">

                            <Row>
                                <Col xl="4" lg="6" xs="12" className="mb-5">
                                    <Card>
                                        <div className="">
                                            <img className="img-fluid" src={imgT4} alt="Laptop displaying interactive dashboard" />
                                        </div>
                                        <Col className="p-3">
                                            <Row className="p-1">
                                                <Col>
                                                    <div className="portfolio-caption-heading text-left">The Benefits of Custom Software: Why Tailor-Made Solutions Are Worth the Investment</div>
                                                </Col>
                                            </Row>
                                            <Row className="p-1">
                                                <Col style={{ textAlign: "left" }} className="sub-text">
                                                    In today's fast-paced business world, companies are constantly seeking ways to stay ahead of the competition, streamline operations, and provide better services to their customers. One of the most effective ways to achieve these goals...
                                                    <Link to="/bespoke">
                                                        read more
                                                    </Link>
                                                </Col>
                                            </Row>
                                            <Row className="p-2">
                                                <Col xs="2">
                                                    <div className="circular--author"> <img src={simg_LW} alt="Author Lawrence Whale" /> </div>
                                                </Col>

                                                <Col xs="10">
                                                    <Row>
                                                        <Col className="portfolio-caption-subheading text-left">
                                                            Lawrence Whale
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col>
                                                            <div className="portfolio-caption-subheading text-left">21/02/2023</div>
                                                        </Col>
                                                        <Col>
                                                            <div className="portfolio-caption-subheading text-left">6 mins</div>
                                                        </Col>
                                                    </Row>
                                                </Col>

                                            </Row>

                                        </Col>
                                    </Card>
                                </Col>

                            </Row>

                        </div>
                    </div>
                </Row>
            </div>

        </div>
    )
}

export default Blog