import React from 'react'
// import logo from './logo.svg';
import { useEffect, useState, useCallback, useRef } from 'react';
import { AiOutlinePartition } from 'react-icons/ai'
import { Col, Row, CardHeader, CardBody, Button, Card, Input, InputGroup, Tooltip } from 'reactstrap';
// import '../App.css';
import '../../../assets/css/Main.css'
// import DataTable from 'react-data-table-component';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-balham.css';
import base from '../../../services/BaseService.js'
import Spinner from '../../shared/loading.js'
// import Filter from '../../shared/filter'
// import { useMsal } from "@azure/msal-react";
// import { loginRequest } from '../../../authconfig';
import BudgetModal from './contactsModal.js'
import Select from 'react-select'
import remove from '../../../assets/icons/remove24.svg';
import removeLrg from '../../../assets/icons/remove32.svg';
import download from '../../../assets/icons/download24.svg';
import ifilter from '../../../assets/icons/filter24.svg';
import ifilterFill from '../../../assets/icons/filterfill24.svg';


const Contacts = () => {

    const gridRef = useRef();
    const [saveItem, setSaveItem] = useState();
    const [budgetID, setBudgetID] = useState();
    const [gridDefaultColDef, setGridDefaultColDef] = useState([]);
    const [budgetColDef, setBudgetColDef] = useState([]);
    const [showModal, setShowModal] = useState(false)
    const [toggleModal, setToggleModal] = useState(false)
    const [expLoading, setExpLoading] = useState(true);
    // const { instance, accounts } = useMsal();
    const [data, setData] = useState([]);
    const [originData, setOriginData] = useState([]);
    const [filter1, setFilter1] = useState(['Active']);
    const [filter3, setFilter3] = useState('');
    const [inputValue, setInputValue] = useState({ value: "Active", label: 'Active' });
    const [tooltipOpen, setTooltipOpen] = useState(false);
    const toggle = () => setTooltipOpen(!tooltipOpen);
    const [filter, setFilter] = useState(true);
    const [showFilter, setShowFilter] = useState(false);

    const options1 = [
        { value: "Active", label: 'Active' },
        { value: "Completed", label: 'Completed' },
        { value: "Hidden", label: 'Hidden' },
    ]

    const initGridVar = useCallback(() => {
        setGridDefaultColDef({
            flex: 1,
            minWidth: 100,
            sortable: true,
            filter: true,
            resizable: true,
            editable: false,
            sortingOrder: ["asc", "desc"],
        });

        setBudgetColDef([
            {
                headerName: "Contact Name",
                field: "contactName",
                // cellClass: "alignCenter widthhhh",
                suppressMovable: true,
                maxWidth: 150
            },

            {
                headerName: "Project",
                field: "name",
                // cellClass: "alignCenter widthhhh",
                suppressMovable: true,
                minWidth: 200,
                maxWidth: 350
            },
            {
                headerName: "Status",
                field: "status",
                // cellClass: "alignCenter widthhhh",
                suppressMovable: true,
                maxWidth: 150
            },
            {
                headerName: "PO Reference",
                field: "contract_po_reference",
                // cellClass: "alignCenter widthhhh",
                suppressMovable: true,
                maxWidth: 150
            },
            {
                headerName: "FA1",
                field: "fA1",
                // cellClass: "alignCenter widthhhh",
                suppressMovable: true,
                maxWidth: 200
            },
            {
                headerName: "FA2",
                field: "fA2",
                // cellClass: "alignCenter widthhhh",
                suppressMovable: true,
                maxWidth: 200
            },
            {
                headerName: "FA3",
                field: "fA3",
                // cellClass: "alignCenter widthhhh",
                suppressMovable: true,
                maxWidth: 200
            },
            {
                headerName: "FA4",
                field: "fA4",
                // cellClass: "alignCenter widthhhh",
                suppressMovable: true,
                maxWidth: 200
            },
            {
                headerName: "FA5",
                field: "fA5",
                // cellClass: "alignCenter widthhhh",
                suppressMovable: true,
                maxWidth: 200
            },
            {
                headerName: "FA6",
                field: "fA6",
                // cellClass: "alignCenter widthhhh",
                suppressMovable: true,
                maxWidth: 200
            },
            {
                headerName: "FA7",
                field: "fA7",
                // cellClass: "alignCenter widthhhh",
                suppressMovable: true,
                maxWidth: 200
            },
            {
                headerName: "Include Timeslips",
                field: "include_timeslips",
                // cellClass: "alignCenter widthhhh",
                suppressMovable: true,
                maxWidth: 200
            },
            {
                headerName: "id",
                field: "id",
                // hidden: true,
                suppressMovable: true,
                hide: true,
                // filter: 'agNumberColumnFilter',
                type: 'numericColumn',
                // setColumnVisible: false,
                // valueFormatter: currencyFormatter
            },
        ]
        );
    }, []);

    const selectChange = (e, id) => {
        var fName = id.name
        if (fName === 'drpStatus') {
            // setInputValue(e.value);
            setFilter1(Array.isArray(e) ? e.map(x => x.value) : []);
            setInputValue(e)
        }
    }

    useEffect(() => {
        const filterData = () => {
            var xData = originData;

            if (filter1.length > 0) {
                xData = xData.filter(f => filter1.some(item => item.includes(f.status)));
                setFilter(true);
                // console.log(filter1)
            }


            if (filter3 !== '') {
                xData = xData.filter(f => f.contract_po_reference.toUpperCase().includes(filter3.toUpperCase()) || f.contactName.toUpperCase().includes(filter3.toUpperCase()) || f.name.toUpperCase().includes(filter3.toUpperCase()));
                setFilter(true);

            }

            if (filter1.length === 0 && filter3 === '') {
                // console.log('filter false')
                setFilter(false);
            }

            setData(xData);

        }
        filterData();
    }, [filter1, filter3, originData]);



    const handleChange = (e) => {
        console.log(e.target.value)
        var searchInput = e.target.value;
        // console.log(searchInput);
        if (searchInput !== '') {
            setFilter3(searchInput);
        }
        else {
            // setFilter(false);
            setFilter3('');
        }
        // setData(originData.filter(f => `${searchInput}`.some(item => item.includes(f.cat_description))));
        // setData(data.filter(f => f.cat_description.indexOf(e.value)))
    }

    const closeFilter = () => {
        setTooltipOpen(false);
        setShowFilter(false);
        setFilter(true);
        setData(originData);
        setInputValue({ value: "Active", label: 'Active' });
        // setInputValue1([]);
        setFilter1(['Active']);
        // setFilter2([]);
        setFilter3('');
        // setFilter4([]);
    }

    const openModal = () => {
        setShowModal(!showModal)
        // //console.log(showModal)
    }

    const closeModal = () => {
        setShowModal(showModal => false)
        // setExpLoading(true)
        setToggleModal(!toggleModal)
    }

    useEffect(() => {
        initGridVar();
    }, [initGridVar]);

    useEffect(() => {
        const getContacts = async () => {
            await base.getAll("ManageData/contacts").then((res) => {
                setOriginData(res);
                setData(res);
                // setBudgetDataF(res);
                // setSelectedValue('Active')
            });
        }
        getContacts();
        setTimeout(() => {
            setExpLoading(false)
        }, [1000])

    }, [saveItem])

    const onBtnExport = useCallback(() => {
        gridRef.current.api.exportDataAsCsv();
    }, []);

    const onSelectionChanged = () => {
        const selectedRows = gridRef.current.api.getSelectedRows();
        if (selectedRows.length === 1) {
            setBudgetID(selectedRows[0].id);
            openModal();
            // //console.log(selectedRows[0].id)
            // setTimeout(() => {
            //     //console.log(budgetID);
            // }, [5000])

        }
        // document.querySelector('#selectedRows').innerHTML =
        //     selectedRows.length === 1 ? selectedRows[0].id : '';
    };

    // useEffect(() => {
    //     console.log(selectedValue.length)
    //     if (selectedValue.length !== 0) {
    //         console.log("here 1")

    //         setBudgetDataF(budgetData.filter(
    //             i => i.status.split(",").some(
    //                 fStatus => selectedValue.includes(fStatus)
    //             )))
    //     }
    //     else {
    //         setBudgetDataF(budgetData)
    //         console.log("here 2")
    //     }
    //     // console.log(budgetData.filter(a => { return a.status.includes(selectedValue) }))
    // }, [selectedValue])

    return (
        // <div>
        <>


            <CardHeader className="pt-3 pb-3">
                <Row>
                    <Col xs="12" sm="9" className="page-header">

                        <AiOutlinePartition size={30} className="mb-1" color="#8bc5c5" />&nbsp;&nbsp;
                        <span className="section-heading">Project Enrichment</span>
                    </Col>
                    {/* <span > */}
                    <Col xs="12" sm="3" className="text-end pe-4">
                        <Button size="sm" color="light border-dark" className="btn-text me-1" style={{ height: "38px" }} onClick={() => setShowFilter(!showFilter)}>
                            {filter ?
                                // <AiFillFilter size={30} className="mb-1" color="#8bc5c5" />
                                <img src={ifilterFill} alt="filter applied" />
                                :
                                // <AiOutlineFilter size={30} className="mb-1" color="#8bc5c5" />
                                <img src={ifilter} alt="filter" />

                            }
                            {/* <img src={filter} alt="filter" /> */}
                        </Button>
                        <Button size="sm" color="light border-dark" className="btn-text me-1" style={{ height: "38px" }} onClick={() => onBtnExport()}>
                            <img src={download} alt="download" />
                        </Button>
                        {/* <Button size="sm" color="light border-dark" className="btn-text" style={{ height: "38px" }} onClick={() => refreshTransactions()}>
                                    <img src={refresh} alt="refresh" />
                                </Button> */}
                    </Col>
                    {/* </span> */}
                </Row>
            </CardHeader>
            <div className="container-fluid">
                <Row>
                    <Col xs="12">

                        {expLoading ?
                            <CardBody style={{ height: "500px" }}>
                                <div className="row align-items-center">
                                    <div className="text-center" >
                                        <Spinner />
                                    </div>
                                </div>
                            </CardBody>
                            :
                            <>

                                {showFilter &&
                                    <Card className="mt-3 ms-3 me-3">
                                        <CardBody style={{ backgroundColor: "#F2F2F2" }}>

                                            <div className="row align-items-center">
                                                <Col xs="12" sm="5" lg="3" className="mb-2">
                                                    <InputGroup>
                                                        <Input type="text" value={filter3} className="input-text-filter" name="keyword" id="keyword" placeholder="Filter by keyword" onChange={handleChange} />
                                                        <Button size="sm" color="" className="btn-text" onClick={() => setFilter3('')}>
                                                            <img src={remove} alt="remove" />
                                                        </Button>
                                                    </InputGroup>
                                                </Col>
                                                <Col xs="12" sm="5" lg="3" className="mb-2">
                                                    <Select className='input-text-filter' closeMenuOnSelect={false} options={options1} isMulti={true} onChange={selectChange} name="drpStatus"
                                                        // className="text-lrf"
                                                        // options={users}
                                                        defaultValue={inputValue}
                                                        value={inputValue}

                                                        placeholder={"Status..."} />
                                                </Col>
                                                <Col className='text-end'>
                                                    <Button size="sm" color="" className="btn-text" id="TooltipExample" onClick={() => closeFilter(false)}>
                                                        <img src={removeLrg} alt="remove" />
                                                        <Tooltip
                                                            // {...args}
                                                            isOpen={tooltipOpen}
                                                            target="TooltipExample"
                                                            toggle={toggle}
                                                            placement={'bottom'}
                                                        >
                                                            Close and reset filters
                                                        </Tooltip>
                                                    </Button>
                                                </Col>

                                            </div>
                                        </CardBody>
                                    </Card>


                                }
                                {/* <div className="example-header">
                                Selection:
                                <span id="selectedRows"></span>
                            </div> */}

                                <CardBody style={{ height: "680px" }}>
                                    <div className="ag-theme-balham" style={{ height: "100%", width: "100%" }} >
                                        <AgGridReact
                                            ref={gridRef}
                                            rowData={data}
                                            columnDefs={budgetColDef}
                                            overlayNoRowsTemplate="No Records Found"
                                            defaultColDef={gridDefaultColDef}
                                            pagination={true}
                                            // onGridReady={onGridReady}
                                            rowSelection={'single'}
                                            // rowSelection={'single'}
                                            paginationPageSize={20}
                                            // gridOptions={gridOptions}
                                            onRowDoubleClicked={onSelectionChanged}
                                        // onGridReady={onGridReady}
                                        // onSelectionChanged={gridRef.current.api.getSelectedRows()}
                                        // onRowDoubleClicked={() => //console.log('Row double clicked')}
                                        // onRowDoubleClicked=(()=> editRow())
                                        >
                                        </AgGridReact>
                                    </div>
                                </CardBody>
                            </>

                        }

                    </Col>

                </Row>
            </div>
            {showModal && <BudgetModal id={budgetID} closeModal={closeModal} showModalForm={true} setSaveItem={setSaveItem} />}
            {/* {showModal && <BudgetModal id={budgetID} openModal={openModal} closeModal={closeModal} showModalForm={true} />} */}

        </>
    )
}

export default Contacts
